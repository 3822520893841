import React from 'react';

class CreateChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 42 : props.width,
      height: !props.height ? 42 : props.height,
    };
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <g id="그룹_1345" data-name="그룹 1345" transform="translate(-940 -457)">
          <rect
            id="사각형_1548"
            data-name="사각형 1548"
            width={this.state.width}
            height={this.state.height}
            transform="translate(940 457)"
            fill="rgba(255,255,255,0)"
          />
          <g id="그룹_1339" data-name="그룹 1339" transform="translate(1 -2)">
            <path
              id="패스_899"
              data-name="패스 899"
              d="M24.494,43.494a19,19,0,1,1,19-19A18.995,18.995,0,0,1,24.494,43.494Zm0-14.25a13.051,13.051,0,0,0-12.16,8.313H16.3a9.442,9.442,0,0,1,16.387,0h3.966a13.051,13.051,0,0,0-12.16-8.312Z"
              transform="translate(935.506 455.506)"
              fill="#fafafa"
            />
            <circle
              id="타원_863"
              data-name="타원 863"
              cx="3.086"
              cy="3.086"
              r="3.086"
              transform="translate(964.259 471.932)"
              fill="#171717"
            />
            <circle
              id="타원_864"
              data-name="타원 864"
              cx="3.086"
              cy="3.086"
              r="3.086"
              transform="translate(949.569 471.932)"
              fill="#171717"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default CreateChannel;
