import React from 'react';

class CreateChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 42 : props.width,
      height: !props.height ? 42 : props.height,
    };
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <g id="그룹_1347" data-name="그룹 1347" transform="translate(-939 -459)">
          <g id="그룹_1327" data-name="그룹 1327">
            <g id="plagiarism-24px" transform="translate(939 459)">
              <g id="그룹_1324" data-name="그룹 1324">
                <rect
                  id="사각형_1545"
                  data-name="사각형 1545"
                  idth={this.state.width}
                  height={this.state.height}
                  fill="none"
                />
              </g>
              <g id="그룹_1326" data-name="그룹 1326" transform="translate(6.993 3.496)">
                <g id="그룹_1325" data-name="그룹 1325">
                  <path
                    id="패스_898"
                    data-name="패스 898"
                    d="M21.482,2H7.5A3.507,3.507,0,0,0,4,5.5V33.468a3.492,3.492,0,0,0,3.479,3.5h21a3.507,3.507,0,0,0,3.5-3.5V12.489ZM23.3,32.506l-3.287-3.287a6.119,6.119,0,1,1,2.465-2.465l3.287,3.287ZM19.734,14.237V4.622l9.615,9.615Z"
                    transform="translate(-4 -2)"
                    fill="#fafafa"
                  />
                  <ellipse
                    id="타원_865"
                    data-name="타원 865"
                    cx="3"
                    cy="2.5"
                    rx="3"
                    ry="2.5"
                    transform="translate(10.007 19.504)"
                    fill="#fafafa"
                  />
                </g>
              </g>
            </g>
            <rect
              id="사각형_1546"
              data-name="사각형 1546"
              width="16"
              height="16"
              transform="translate(952 478)"
              fill="#fafafa"
            />
          </g>
          <g id="그룹_1328" data-name="그룹 1328" transform="translate(4.5 3.729)">
            <line
              id="선_247"
              data-name="선 247"
              y2="9.204"
              transform="translate(955.5 475.5)"
              fill="none"
              stroke="#171717"
              strokeWidth="3"
            />
            <line
              id="선_248"
              data-name="선 248"
              y2="2.533"
              transform="translate(955.5 486.238)"
              fill="none"
              stroke="#171717"
              strokeWidth="3"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default CreateChannel;
