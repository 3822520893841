import React from 'react';

class ServerIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 42 : props.width,
      height: !props.height ? 37.333 : props.height,
    };
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <path
          id="exclamation-triangle-solid"
          d="M41.527,32.084A3.5,3.5,0,0,1,38.5,37.333H3.5A3.5,3.5,0,0,1,.472,32.084l17.5-30.335a3.5,3.5,0,0,1,6.063,0l17.5,30.335ZM21,25.812a3.354,3.354,0,1,0,3.354,3.354A3.354,3.354,0,0,0,21,25.812ZM17.815,13.756l.541,9.917a.875.875,0,0,0,.874.827h3.54a.875.875,0,0,0,.874-.827l.541-9.917a.875.875,0,0,0-.874-.923H18.689a.875.875,0,0,0-.874.923Z"
          transform="translate(0)"
          fill="#fafafa"
        />
      </svg>
    );
  }
}

export default ServerIcon;
