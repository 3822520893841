import React from 'react';
import CommonIcon from '../../assets/img/error/common';
import NotFoundIcon from '../../assets/img/error/notFound';
import ForbiddenIcon from '../../assets/img/error/forbidden';
import UnauthIcon from '../../assets/img/error/unauth';
import serverCheck from '../../assets/img/error/serverCheck';
import Router from 'next/router';
import URL_CONSTANT from '../../constants/url.constants.js';
import PropTypes from 'prop-types';
import { withTranslation } from 'utils/with-i18next';
import Head from 'next/head';

class Error extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.error = {
      common: {
        title: 'error',
        desc: 'common',
        icon: CommonIcon,
      },
      notFound: {
        title: 'page not found',
        desc: 'notFound',
        icon: NotFoundIcon,
      },
      forbidden: {
        title: 'forbidden',
        desc: 'forbidden',
        icon: ForbiddenIcon,
      },
      unauth: {
        title: 'unauthorized',
        desc: 'unauth',
        icon: UnauthIcon,
      },
      serverCheck: {
        title: 'serverCheck',
        desc: 'serverCheck',
        icon: serverCheck,
      },
    };
  }
  render() {
    const { type, t } = this.props;
    const errorData = this.error[this.props.type];

    return (
      <>
        <Head>
          <title>BGMs - error</title>
        </Head>
        <div className="error">
          <errorData.icon />
          <h2>{errorData.title}</h2>
          <p className="contents">
            {t(`desc.${errorData.desc}`)}
            <br />
            {t('desc-common.1')} <a href="mailto:cs@bgms.live?subject=[BGMs] Inquiry email">{t('desc-common.here')}</a>
            {t('desc-common.2')}
          </p>
          <button className="btn" onClick={() => Router.push(URL_CONSTANT.home.url)}>
            {t('back')}
          </button>
        </div>
      </>
    );
  }
}

Error.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('error')(Error);
