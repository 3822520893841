import React from 'react';

class CreateChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 42 : props.width,
      height: !props.height ? 42 : props.height,
    };
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <path
          id="bomb-solid"
          d="M36.135,7.261l-4.266,4.266L34.043,13.7a1.961,1.961,0,0,1,0,2.781l-1.427,1.428a17.064,17.064,0,1,1-8.531-8.525l1.427-1.428a1.96,1.96,0,0,1,2.781,0l2.174,2.174,4.266-4.266Zm4.881-2.338H39.047a.985.985,0,0,0,0,1.969h1.969a.985.985,0,0,0,0-1.969ZM36.094,0a.987.987,0,0,0-.984.985V2.954a.984.984,0,1,0,1.969,0V.985A.987.987,0,0,0,36.094,0Zm2.781,4.513,1.395-1.395a.986.986,0,0,0-1.395-1.395L37.48,3.118a.986.986,0,1,0,1.395,1.395Zm-5.562,0a.986.986,0,1,0,1.395-1.395L33.313,1.723a.986.986,0,1,0-1.395,1.395ZM38.875,7.3A.986.986,0,0,0,37.48,8.7l1.395,1.395A.986.986,0,0,0,40.269,8.7ZM9.188,22.317a5.255,5.255,0,0,1,5.25-5.251,1.313,1.313,0,0,0,0-2.626,7.888,7.888,0,0,0-7.875,7.877,1.313,1.313,0,1,0,2.625,0Z"
          fill="#fafafa"
        />
      </svg>
    );
  }
}

export default CreateChannel;
