import React from 'react';

class CreateChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 42 : props.width,
      height: !props.height ? 42 : props.height,
    };
  }

  render() {
    return (
      <svg
        id="admin_panel_settings-24px"
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={`0 0 ${this.state.width} ${this.state.height}`}>
        <g id="그룹_1332" data-name="그룹 1332">
          <rect
            id="사각형_1547"
            data-name="사각형 1547"
            width={this.state.width}
            height={this.state.height}
            fill="none"
          />
        </g>
        <g id="그룹_1334" data-name="그룹 1334" transform="translate(5.249 5.249)">
          <g id="그룹_1333" data-name="그룹 1333">
            <path
              id="패스_900"
              data-name="패스 900"
              d="M27.493,17a11.613,11.613,0,0,1,1.75.157V8.721L16.121,3,3,8.721v8.59c0,7.943,5.6,15.378,13.121,17.18a16.036,16.036,0,0,0,2.8-.962A10.48,10.48,0,0,1,27.493,17Z"
              transform="translate(-3 -3)"
              fill="#fafafa"
            />
            <path
              id="패스_901"
              data-name="패스 901"
              d="M20,13a7,7,0,1,0,7,7A7,7,0,0,0,20,13Zm0,2.414a1.959,1.959,0,1,1-1.959,1.959A1.967,1.967,0,0,1,20,15.414Zm0,9.395a4.767,4.767,0,0,1-3.919-2.047c.087-1.26,2.642-1.889,3.919-1.889s3.831.63,3.919,1.889A4.767,4.767,0,0,1,20,24.809Z"
              transform="translate(4.495 4.495)"
              fill="#fafafa"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default CreateChannel;
